import React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import Icon from '../components/iconfontCpt'
import '../style/mogdbStack.scoped.scss'
import { Link } from "gatsby"

const MogdbStack = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true}>
      <Seo title="MogDB: MogdbStack"/>
      <div className="mogdbstack-container">
        <div className="header-placeholder"></div>
        <div className="mogdbstack-banner">
          <div className="product-banner-box black-thema">
            <p><FormattedMessage id="mogdbstack.title" /></p>
            <h1 className="product-banner-title"><FormattedMessage id="mogdbstack.pageTitle" /><i className="mogdbstack"></i></h1>
            <div className="product-banner-desc">
              <p><FormattedMessage id="mogdbstack.pageContent" /></p>
            </div>
            <div className="btn-group">
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb-stack/latest/release`} target="_blank" className="btn"><FormattedMessage id="index.download" /></a>
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb-stack/latest/overview`} target="_blank" className="btn"><FormattedMessage id="index.docs" /></a>
            </div>
          </div>
        </div>
        <div className="bannerTip">
          <div>
            <div className="left">
              <div className="content">
                <i className="icon icon0"></i>
                <p className="title"><FormattedMessage id="mogdbstack.tipTitle1" /></p>
                <p className="context"><FormattedMessage id="mogdbstack.tip1" /></p>
              </div>
              <i className="bg"></i>
            </div>
            <div className="right">
              <div>
                <div className="content">
                  <i className="icon icon1"></i>
                  <p className="title"><FormattedMessage id="mogdbstack.tipTitle2" /></p>
                  <p className="context"><FormattedMessage id="mogdbstack.tip2" /></p>
                </div>
                <div className="content">
                  <i className="icon icon2"></i>
                  <p className="title"><FormattedMessage id="mogdbstack.tipTitle3" /></p>
                  <p className="context"><FormattedMessage id="mogdbstack.tip3" /></p>
                </div>
              </div>
              <div>
                <div className="content">
                  <i className="icon icon3"></i>
                  <p className="title"><FormattedMessage id="mogdbstack.tipTitle4" /></p>
                  <p className="context"><FormattedMessage id="mogdbstack.tip4" /></p>
                </div>
                <div className="content">
                  <i className="icon icon4"></i>
                  <p className="title"><FormattedMessage id="mogdbstack.tipTitle5" /></p>
                  <p className="context"><FormattedMessage id="mogdbstack.tip5" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`system ${pageLang === 'en' ? "s-content-en" : "s-content"}`}>
          <div className="title"><FormattedMessage id="mogdbstack.system" /></div>
          <div className="s-introduce"></div>
        </div>
        <div className="experience">
          <div>
            <div className="e-title"><FormattedMessage id="index.experience" /></div>
            <div className="e-content"><FormattedMessage id="mogdbstack.experienceContent" /></div>
            <div className="btn-group">
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb-stack/latest/release`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
              <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default MogdbStack